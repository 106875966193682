@import "__config";
//@import "sanitize.css/sanitize.css";

html {
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	font-family: $font_family;
	font-weight: 500;
	line-height: 1.7;
	letter-spacing: 0.025em;
	color: $font_color;
	background: $bg_color;
}

audio,
canvas,
iframe,
img,
svg,
video,
embed,
object {
	vertical-align: top;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	transition: all 0.1s ease;
	text-decoration: none;
	&:hover {
		color: inherit;
		text-decoration: none;
		opacity: 0.7;
	}
}

address,
small,
input,
textarea,
select {
	font: inherit;
}

button {
	font: inherit;
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	appearance: none;
	transition: all 0.1s ease;
	&:hover {
		opacity: 0.7;
	}
}

label {
	margin: 0;
}

.wrapper {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.sidebar {
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	background: #1d3048;
	min-width: 230px;
	height: 100%;
	transform: translate(-100%, 0);
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease;
	&._show {
		opacity: 1;
		pointer-events: auto;
		transform: translate(0, 0);
	}
	@media (--bp3) {
		width: 230px;
		opacity: 1;
		pointer-events: auto;
		transform: translate(0, 0);
		&._close {
			transform: translate(-100%, 0);
		}
	}
	&__inner {
		overflow: auto;
		height: 100%;
	}
	&__logo {
		text-align: center;
		line-height: 0;
		margin: 0 auto;
		@media (--bp3) {
			height: 69px;
			position: relative;
			&::after {
				content: "";
				display: block;
				width: 210px;
				height: 1px;
				background: #4a596d;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
		& a {
			display: block;
			padding: 23px 0;
			@media (--bp3) {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				padding: 0;
			}
		}
		& img {
			width: 147px;
			@media (--bp3) {
				width: 140px;
			}
		}
	}
}

.menuOverlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 49;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease;
	&._show {
		opacity: 0.5;
		pointer-events: auto;
	}
	@media (--bp3) {
		display: none;
	}
}

.menu {
	padding: 0 0 30px;
	@media (--bp3) {
		padding: 26px 0 30px;
	}
	&__list {
		line-height: 20px;
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&__item {
		@media (--bp3) {
			margin: 0 0 10px;
		}
		&::before {
			content: none;
		}
		& a {
			display: block;
			position: relative;
			padding: 11px 35px 11px 72px;
			transition: none;
			&::before {
				content: "";
				display: block;
				background-repeat: no-repeat;
				background-size: contain;
				opacity: 0.5;
				position: absolute;
				top: 50%;
				left: 45px;
				transform: translate(-50%, -50%);
			}
		}
		&--current a,
		& a:hover {
			opacity: 1;
			@media (--bp3) {
				background: #005094;
			}
		}
		&--home a::before {
			width: 17px;
			height: 17px;
			background-image: $svg_home;
		}
		&--drive a::before {
			width: 18px;
			height: 18px;
			background-image: $svg_calendar;
		}
		&--result a::before {
			width: 18px;
			height: 16px;
			background-image: $svg_list;
		}
		&--report a::before {
			width: 22px;
			height: 22px;
			background-image: $svg_note;
		}
		&--reserve a::before {
			width: 19px;
			height: 19px;
			background-image: $svg_time;
		}
		&--upload a::before {
			width: 21px;
			height: 21px;
			background-image: $svg_cloud;
		}
		&--cars a::before {
			width: 21px;
			height: 21px;
			background-image: $svg_cars;
		}
		&--group a::before {
			width: 21px;
			height: 21px;
			background-image: $svg_people;
		}
		&--admin a::before {
			width: 26px;
			height: 23px;
			background-image: $svg_admin;
		}
		&--support a::before {
			width: 21px;
			height: 21px;
			background-image: $svg_support;
		}
	}
	&__close {
		display: block;
		width: 25px;
		height: 25px;
		position: absolute;
		top: 15px;
		right: -35px;
		@media (--bp3) {
			display: none;
		}
	}
}

.contents {
	@media (--bp3) {
		padding: 0 0 0 230px;
		transition: all 0.3s ease;
		&._menuClose {
			padding: 0;
		}
	}
}

.header {
	color: #fff;
	background: $site_color;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 40;
	@media (--bp2) {
		height: 70px;
	}
	@media (--bp3) {
		width: calc(100% - 230px);
		left: auto;
		right: 0;
		transition: all 0.3s ease;
		&._menuClose {
			width: 100%;
		}
	}
	&__menuBtn {
		position: absolute;
		top: 0;
		left: 0;
		@media (--bp2) {
			left: 8px;
		}
	}
	&__heading {
		font-size: 16px;
		font-weight: 700;
		display: flex;
		align-items: center;
		margin: 0;
		@media (--bp2) {
			font-size: 24px;
			font-weight: 400;
		}
		&::before {
			content: "";
			display: block;
			background-repeat: no-repeat;
			background-size: contain;
			opacity: 0.5;
			margin: 0 14px 0 0;
		}
		&--home {
			&::before {
				width: 17px;
				height: 17px;
				background-image: $svg_home;
				@media (--bp2) {
					width: 25px;
					height: 25px;
				}
			}
		}
		&--account {
			&::before {
				width: 17px;
				height: 17px;
				background-image: $svg_person;
				@media (--bp2) {
					width: 25px;
					height: 25px;
				}
			}
		}
		&--report {
			&::before {
				width: 19px;
				height: 19px;
				background-image: $svg_note;
				@media (--bp2) {
					width: 29px;
					height: 29px;
				}
			}
		}
		&--drive {
			&::before {
				width: 15px;
				height: 15px;
				background-image: $svg_calendar;
				@media (--bp2) {
					width: 24px;
					height: 24px;
				}
			}
		}
		&--result {
			&::before {
				width: 17px;
				height: 15px;
				background-image: $svg_list;
				@media (--bp2) {
					width: 25px;
					height: 22px;
				}
			}
		}
		&--upload {
			&::before {
				width: 20px;
				height: 20px;
				background-image: $svg_cloud;
				@media (--bp2) {
					width: 27px;
					height: 27px;
				}
			}
		}
		&--cars {
			&::before {
				width: 20px;
				height: 20px;
				background-image: $svg_cars;
				@media (--bp2) {
					width: 27px;
					height: 27px;
				}
			}
		}
		&--reserve {
			&::before {
				width: 18px;
				height: 18px;
				background-image: $svg_time;
				@media (--bp2) {
					width: 24px;
					height: 24px;
				}
			}
		}
		&--group {
			&::before {
				width: 18px;
				height: 18px;
				background-image: $svg_people;
				@media (--bp2) {
					width: 28px;
					height: 28px;
				}
			}
		}
		&--admin {
			&::before {
				width: 22px;
				height: 19px;
				background-image: $svg_admin;
				@media (--bp2) {
					width: 33px;
					height: 29px;
				}
			}
		}
		&--support {
			&::before {
				width: 18px;
				height: 18px;
				background-image: $svg_support;
				@media (--bp2) {
					width: 27px;
					height: 27px;
				}
			}
		}
	}
	&__user {
		position: absolute;
		top: 0;
		right: 0;
		width: 44px;
		height: 100%;
		@media (--bp2) {
			width: auto;
			right: 20px;
		}
		@media (--bp4) {
			right: 45px;
		}
	}
	&__userBtn {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		@media (--bp2) {
			padding: 0 20px;
		}
		&::before {
			content: "";
			display: block;
			width: 15px;
			height: 15px;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: $svg_person;
			margin: auto;
			@media (--bp2) {
				width: 17px;
				height: 17px;
			}
		}
		&::after {
			@media (--bp2) {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				transform-origin: center center;
				transform: rotate(45deg);
			}
		}
		& ._name {
			display: none;
			@media (--bp2) {
				color: #fff;
				font-weight: 400;
				display: block;
				margin: 0 19px 0 15px;
			}
		}
	}
}

.userMenu {
	background: $site_color;
	border-radius: 5px;
	min-width: 200px;
	padding: 8px 0;
	top: 55px;
	right: 5px;
	transform: translate(0, -20%);
	transition: all 0.2s ease;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	overflow: hidden;
	&._show {
		opacity: 1;
		transform: translate(0, 0);
		pointer-events: auto;
	}
	@media (--bp3) {
		padding: 0;
		top: 73px;
	}
	&__list {
		line-height: 21px;
		margin: 0;
		padding: 0;
		list-style: none;
		white-space: nowrap;
	}
	&__item {
		&::before {
			content: none;
		}
		& a {
			display: block;
			padding: 12px 22px 12px 65px;
			position: relative;
			transition: none;
			&::before {
				content: "";
				display: block;
				background-repeat: no-repeat;
				background-size: contain;
				opacity: 0.5;
				position: absolute;
				top: 50%;
				left: 22px;
				transform: translate(0, -50%);
			}
			&:hover {
				opacity: 1;
			}
			@media (--bp3) {
				padding: 15px 22px 15px 55px;
				&:hover {
					background: #5d85ca;
				}
			}
		}
		&:not(:first-child) {
			@media (--bp3) {
				border-top: 1px solid #6c87b5;
			}
		}
		&--profile a::before {
			width: 28px;
			height: 21px;
			background-image: $svg_profile;
			@media (--bp3) {
				width: 23px;
				height: 17px;
			}
		}
		&--password a::before {
			width: 28px;
			height: 15px;
			background-image: $svg_password;
			@media (--bp3) {
				width: 23px;
				height: 12px;
			}
		}
		&--logout a::before {
			width: 24px;
			height: 21px;
			background-image: $svg_logout;
			left: 23px;
			transform: translate(0, -50%) scaleX(-1);
			@media (--bp3) {
				width: 20px;
				height: 17px;
			}
		}
	}
}

.menuBtn {
	width: 50px;
	height: 100%;
	@media (--bp2) {
		width: 70px;
	}
	&__inner {
		width: 18px;
		height: 2px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -1px 0 0 -9px;
		@media (--bp2) {
			width: 22px;
		}
		&::before,
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: inherit;
			position: absolute;
		}
		&::before {
			top: -5px;
			@media (--bp2) {
				top: -7px;
			}
		}
		&::after {
			bottom: -5px;
			@media (--bp2) {
				bottom: -7px;
			}
		}
	}
}

.main {
	padding: calc(50px - #{percentage(10 / $sp_ref)}) percentage(20 / $sp_ref)
		50px;
	@media (--bp2) {
		padding: 80px 30px 50px;
	}
	&__row {
		@media (--bp2) {
			display: flex;
			justify-content: space-between;
		}
	}
}

.myCard {
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	background: #fff;
	padding: percentage(35 / $sp_ref) percentage(20 / $sp_ref);
	margin: percentage(30 / $sp_ref) 0 0;
	position: relative;
	@media (--bp2) {
		padding: 20px;
		margin: 15px 0 0;
	}
	&--1 {
		@media (--bp2) {
			min-height: calc(100vh - 120px);
		}
	}
	&--2 {
		@media (--bp2) {
			width: percentage(547 / 1110);
		}
	}
	&__header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 percentage(10 / $sp_ref);
		margin: 0 0 20px;
		@media (--bp2) {
			margin: 0 0 25px;
		}
	}
	&__heading {
		color: $site_color;
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		padding: 0 16px;
		margin: 0;
		position: relative;
		@media (--bp2) {
			font-size: 20px;
			font-weight: 500;
			line-height: 25px;
		}
		&::before {
			content: "";
			display: block;
			width: 4px;
			height: 19px;
			background: $site_color;
			position: absolute;
			top: 0;
			left: 0;
			@media (--bp2) {
				width: 5px;
				height: 25px;
			}
		}
	}
	&__iconHeading {
		color: $site_color;
		font-size: 16px;
		font-weight: 700;
		display: flex;
		align-items: center;
		margin: 0 auto;
		position: relative;
		@media (--bp2) {
			font-size: 20px;
			font-weight: 500;
			line-height: 25px;
		}
		& img {
			margin: 0 12px -2px 0;
			width: 22px;
			@media (--bp2) {
				width: 28px;
			}
		}
		&--myAccount {
			@media (--bp2) {
				margin: 0 auto 5px;
			}
			& img {
				width: 24px;
				@media (--bp2) {
					width: 29px;
				}
			}
		}
		&--myReserve {
			@media (--bp2) {
				margin: 0 auto 5px;
			}
			& img {
				width: 22px;
				@media (--bp2) {
					width: 28px;
				}
			}
		}
		&--driveList {
			justify-content: center;
			@media (--bp2) {
				margin: 0;
			}
			& img {
				width: 22px;
				margin: 0 12px -2px 0;
				@media (--bp2) {
					width: 28px;
					margin: 0 12px -4px 0;
				}
			}
		}
		&--driveInfo {
			margin: 0 auto 0 0;
			& img {
				width: 16px;
				@media (--bp2) {
					width: 26px;
				}
			}
		}
		&--dataOutput {
			justify-content: center;
			& img {
				width: 16px;
				@media (--bp2) {
					width: 21px;
				}
			}
		}
		&--routeInfo {
			& img {
				width: 21px;
				@media (--bp2) {
					width: 28px;
				}
			}
		}
		&--driveHistory {
			& img {
				width: 23px;
				@media (--bp2) {
					width: 28px;
				}
			}
		}
		&--speedInfo {
			& img {
				width: 19px;
				@media (--bp2) {
					width: 29px;
				}
			}
		}
	}
	&__desc {
		color: $site_color;
		width: 100%;
		padding: 0 0 0 16px;
		margin: 10px 0 0;
	}
	&__body {
		&--scroll {
			@media (--bp2) {
				overflow-x: auto;
			}
		}
	}
}

.backBtn {
	& a {
		color: #fff;
		display: block;
		text-indent: -9999px;
		background-color: $blue_2;
		background-image: $svg_back;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		background-position: center center;
		width: 40px;
		height: 40px;
		border-radius: 5px;
		@media (--bp2) {
			text-indent: 0;
			background-image: none;
			display: flex;
			justify-content: center;
			align-items: center;
			width: auto;
			height: 35px;
			padding: 0 20px;
			&::before {
				content: "";
				display: block;
				background-repeat: no-repeat;
				background-size: contain;
				width: 18px;
				height: 18px;
				background-image: $svg_back;
				margin: 0 10px 0 0;
			}
		}
	}
}

.blueDl {
	@include simpleDl;
	&__dt {
		background: $blue_2;
		border-radius: 5px 5px 0 0;
	}
	&__dd {
		color: $site_color;
		background: $blue_light;
	}
}

.redDl {
	@include simpleDl;
	&__dt {
		background: $red_2;
		border-radius: 5px 5px 0 0;
	}
	&__dd {
		color: $red_1;
		background: $red_light;
	}
}

.datepicker table {
	min-width: 240px;
}

.acdnWrap {
	@extend %acdnDefault;
}

.myModal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: percentage(48 / $sp_ref) percentage(20 / $sp_ref);
	display: flex;
	flex-direction: column;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease;
	&.show {
		opacity: 1;
		pointer-events: auto;
	}
	@media (--bp2) {
		padding: 60px 20px;
	}
	&__inner {
		background: #fff;
		border-radius: 5px;
		width: 100%;
		max-width: 1040px;
		margin: auto;
		padding: 15px percentage(20 / 710);
		position: relative;
		z-index: 1;
		@media (--bp2) {
			padding: 27px 20px;
		}
	}
	&__header {
		display: flex;
		justify-content: space-between;
		padding: 0 percentage(10 / $sp_ref);
		margin: 0 0 20px;
		@media (--bp2) {
			margin: 0 0 25px;
		}
	}
	&__iconHeading {
		color: $site_color;
		font-size: 16px;
		font-weight: 700;
		display: flex;
		align-items: center;
		margin: 0 auto;
		position: relative;
		@media (--bp2) {
			font-size: 20px;
			font-weight: 500;
			line-height: 30px;
		}
		&--filter {
			& img {
				width: 18px;
				@media (--bp2) {
					width: 22px;
				}
			}
		}
		& img {
			margin: 0 12px 0 0;
		}
	}
	&__body {
		@media (--bp2) {
			overflow-x: auto;
		}
	}
	&__closeBtn {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 25px;
		height: 25px;
		@media (--bp2) {
			top: 27px;
			right: 32px;
			width: 30px;
			height: 30px;
		}
	}
}

.myModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease;
	&.show {
		opacity: 1;
		pointer-events: auto;
	}
}

.myFields {
	@include myFields;
	& select {
		border: 1px solid #d4d4d4;
		border-radius: 5px;
		background: #fff;
		box-shadow: none;
		font-size: 16px;
		width: 100%;
		height: 45px;
		padding: 0 0 0 0.5em;
		@media (--bp2) {
			height: 35px;
		}
	}
	&__submitBtn {
		appearance: none;
		font-size: 14px;
		line-height: 25px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		border-radius: 5px;
		border: none;
		background: $site_color;
		padding: 10px;
		@media (--bp2) {
			line-height: 26px;
			width: auto;
			padding: 7px 20px;
			margin: 0 5px;
			position: absolute;
			top: 22px;
			right: 100px;
		}
		&::before {
			content: "";
			display: block;
			background-repeat: no-repeat;
			background-size: contain;
			margin: 0 12px -2px 0;
		}
		&--download {
			&::before {
				width: 10px;
				height: 14px;
				background-image: $svg_download;
			}
		}
	}
}

.fieldList2col {
	@media (--bp2) {
		display: flex;
		justify-content: space-between;
	}
	@media (--bp4) {
		justify-content: center;
	}
}

.fieldList {
	@media (--bp4) {
		display: table-row-group;
	}
	&--col {
		@media (--bp2) {
			width: 49%;
		}
		@media (--bp4) {
			width: auto;
			margin: 0 25px;
		}
	}
	&__dl {
		margin: 0 0 10px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@media (--bp4) {
			text-align: right;
			display: table-row;
			width: 100%;
		}
		&--row {
			flex-wrap: nowrap;
		}
	}
	&__dt {
		font-weight: 400;
		padding: 0 1em 0 0;
		flex-shrink: 0;
		@media (--bp4) {
			display: table-cell;
			white-space: nowrap;
			padding: 0 1em 10px 0;
		}
	}
	&__dd {
		width: 100%;
		margin: 0;
		@media (--bp4) {
			display: table-cell;
			width: auto;
			padding: 0 0 10px;
		}
		&--s {
			width: auto;
		}
	}
	& input[type="text"],
	& input[type="email"],
	& input[type="password"],
	& input[type="date"],
	& input[type="number"] {
		appearance: none;
		border: 1px solid #d4d4d4;
		border-radius: 5px;
		background: #fff;
		padding: 8px;
		width: 100%;
		@media (--bp4) {
			padding: 3px 8px;
		}
	}
}

.checkList {
	color: #333;
	font-weight: 400;
	text-align-last: left;
	overflow: auto;
	height: 210px;
	&::-webkit-scrollbar {
		width: 7px;
		background: #d9d9d9;
	}
	&::-webkit-scrollbar-piece {
		background: #d9d9d9;
	}
	&::-webkit-scrollbar-piece:start {
		background: #d9d9d9;
	}
	&::-webkit-scrollbar-thumb {
		background: #9b9b9b;
	}
	&--l {
		height: auto;
		min-height: 210px;
		max-height: 535px;
	}
	@media (--bp2) {
		font-size: 16px;
	}
	&__list {
		margin: 0;
		padding: 5px 0;
		list-style: none;
	}
	& input[type="checkbox"] {
		position: absolute;
		z-index: -1;
		visibility: hidden;
		opacity: 0;
	}
	&__label {
		display: flex;
		align-items: center;
		padding: 5px 10px;
		&::before {
			@extend %icon;
			width: 18px;
			height: 18px;
			background: $svg_checkbox_bg;
			margin: 0 12px 0 0;
		}
		&--all {
			padding: 10px;
			border-bottom: 1px solid #c4c4c4;
		}
	}
	& input:checked + &__label {
		&::before {
			background: $svg_checkbox_check;
		}
	}
}

.radioList {
	color: #333;
	font-weight: 400;
	text-align-last: left;
	overflow: auto;
	max-height: 210px;
	@media (--bp2) {
		font-size: 16px;
	}
	&__list {
		margin: 0;
		padding: 5px 0;
		list-style: none;
	}
	& input[type="radio"] {
		position: absolute;
		z-index: -1;
		visibility: hidden;
	}
	&__label {
		display: flex;
		align-items: center;
		padding: 5px 10px;
		&::before {
			content: "";
			display: block;
			width: 23px;
			height: 23px;
			border-radius: 50%;
			box-shadow: 0 0 0 1px #d6d6d6 inset;
			background: #fff;
			margin: 0 10px 0 0;
		}
		input[type="radio"]:checked + &::before {
			box-shadow: 0 0 0 7px $site_color inset;
		}
	}
}

.messageModal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: percentage(48 / $sp_ref) percentage(20 / $sp_ref);
	display: flex;
	flex-direction: column;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease;
	&.show {
		opacity: 1;
		pointer-events: auto;
	}
	@media (--bp2) {
		padding: 60px 20px;
	}
	&__inner {
		width: 100%;
		max-width: 470px;
		margin: auto;
		position: relative;
		z-index: 1;
	}
	&__heading {
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		text-align: center;
		padding: 11px;
		margin: 0;
		background: #8cb660;
		border-radius: 5px 5px 0 0;
		&--blue {
			background: $blue_2;
		}
		&--red {
			background: $red_2;
		}
	}
	&__body {
		font-weight: 400;
		text-align: center;
		padding: 50px;
		background: #fff;
		border-radius: 0 0 5px 5px;
		@media (--bp2) {
		}
	}
	&__btns {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin: 50px 0 0;
	}
	&__btn {
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		border-radius: 5px;
		background: #339900;
		padding: 0 1em;
		min-width: 120px;
		margin: 0 10px;
		&--cancel {
			background: #939393;
		}
		&--submit,
		&--delete {
			&::before {
				@extend %icon;
				margin: 0 9px 0 0;
			}
		}
		&--submit {
			background: $site_color;
			&::before {
				width: 15px;
				height: 15px;
				background-image: $svg_check;
			}
		}
		&--delete {
			background: $red_1;
			&::before {
				width: 13px;
				height: 16px;
				background-image: $svg_trashbox;
			}
		}
	}
	&__closeBtn {
		position: absolute;
		top: 10px;
		right: 20px;
		width: 30px;
		height: 30px;
		&::before {
			content: "";
			display: block;
			width: 90%;
			height: 90%;
			background: #fff;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		& img {
			position: relative;
			z-index: 1;
		}
	}
}

.messageModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease;
	&.show {
		opacity: 1;
		pointer-events: auto;
	}
}

.ui-timepicker-standard a {
	transition: none;
}

.btn-primary {
	background-color: $site_color;
	border-color: $site_color;
	&.disabled,
	&:disabled {
		background-color: $blue_2;
		border-color: $blue_2;
	}
	&.focus,
	&:focus,
	&:hover {
		background-color: #005094;
		border-color: #005094;
	}
}

/* スクロールアニメーション用
.anm {
	opacity: 0;
	transition: all 0.5s ease-out;
	&._show {
		opacity: 1;
		transform: translate(0, 0);
	}
	&--up {
		transform: translate(0, 100px);
	}
	&--lr {
		transform: translate(-60px, 0);
	}
	&--rl {
		transform: translate(60px, 0);
	}
}
*/

/* アコーディオン用 サンプル
.acdn-sample {
	&.acdn-active {
		height: 0;
		overflow: hidden;
		transition: all 0.5s ease;
		@media (--min) {
			height: auto;
		}
	}
	&.open {
		height: auto;
	}
}
*/
